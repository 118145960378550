
// Fonts
//@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "~vue2-dropzone/dist/vue2Dropzone.min.css";

.wrapper {
  max-width: 960px;
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  background: #fff;
}

img.preview {
  width: 100%;
}

.description-row {
  margin: 20px;
}

.buttons-row {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: 30px 0;

  button {
    flex: 0 1;
  }
}

.stepper {
  list-style: none;
  margin: 20px 0;
  padding: 0;
  display: flex;

  li {
    flex: 1 0;

    i {
      background: #adadad;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      color: white;
      font-style: normal;
    }
  }

  li.active {
    font-weight: bold;

    i {
      background: #1fb567;

    }
  }
}

.error-box {
  width: 100%;
  background: #C62828;
  margin: 10px 0;
  padding: 5px 10px;
  color: white;
  border-radius: 3px;
}

.btn-upload {
  float: right;
  margin: 20px 0;
}

.download-code {
  text-align: center;
  display: block;
  font-size: 18px;
  border: 1px dashed #ccc;
  padding: 10px;
}

h2 {
  font-size: 24px;
  text-align: center;
  margin: 40px;
  font-weight: 100;
}

.vue-dropzone {
  border: 1px dashed #E5E5E5 !important;
}

#footer {
  width: 100%;
  //background-color: rgb(68, 67, 73);
  padding: 20px;
  color: white;

  small {
    font-size: 12px;
  }
}

.ticket-wrapper {
  background: url('/img/ticket.jpg');
  background-size: cover;
  width: 580px;
  height: 166px;
  position: relative;
}

.croppa-container {
  position: absolute;
  top: 14px;
  left: 10px;
}

.modal-wrapper {
  padding: 20px;
}

.theme--light .btn:not(.btn--icon):not(.btn--flat) {
  background-color: #4CAF50;
  color: white;
}

body {
  background: #f5f5f5;
}

#app {
  background: #f5f5f5;
}

body .v--modal-overlay {
  background: none;
}

body .v--modal {
  box-shadow: none;
}

body {
  background: white;
}
#app {
  background: white;
}
